import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import videoSrc from "./Video/VideoSTO.mp4";
import { FaTooth, FaFirstAid, FaArrowLeft } from 'react-icons/fa';
import '../style/VideoPlayer.css';

function VideoPlayer() {
  const videoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (videoRef.current) {
      const playPromise = videoRef.current.play();
      
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.log("Autoplay prevented:", error);
        });
      }
    }
  }, []);

  const tips = [
    { icon: <FaFirstAid />, text: "Mordre sur une compresse stérile pendant 30 minutes" },
    { icon: <FaTooth />, text: "Éviter les aliments chauds et durs pendant 24h" },
    { icon: <FaTooth />, text: "Ne pas rincer la bouche vigoureusement dans les 24h" },
    { icon: <FaTooth />, text: "Reprendre le brossage doux après 24h" }
  ];

  return (
    <div className="video-page-container">


      <div className="content-wrapper">
        {/* Conteneur vidéo ajusté */}
        <div className="medium-video-container">
          <video 
            ref={videoRef}
            controls 
            autoPlay
            className="medium-video"
            playsInline
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
        </div>

        <div className="medical-advice-container">
          <h2 className="medical-title">
            <FaTooth className="icon-spacing" />
            Conseils post-extraction dentaire
          </h2>
          
          <div className="medical-tips-grid">
            {tips.map((tip, index) => (
              <div key={index} className="medical-tip-card">
                <div className="tip-icon">{tip.icon}</div>
                <p className="tip-text">{tip.text}</p>
              </div>
            ))}
          </div>

          <div className="medical-note">
            <p>Consultez toujours votre dentiste en cas de saignement persistant ou de douleur intense.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;