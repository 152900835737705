import React, { useState, useEffect } from "react";
import NavBar from "../composant/NavBar";
import Footer from '../composant/Footer';
import Header from '../composant/Header';
import VideoPlayer from './Elements/videoSto';
import "./style/video.css";

function Video() {
    const [showButton, setShowButton] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        const handleScroll = () => setShowButton(window.scrollY > 200);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className="video-app-container">
            <div className="video-page-container">
                <div className="background-pattern"></div>
                <NavBar />
                
                <main className="video-main-content">
                    <VideoPlayer />
                </main>
                
                {showButton && (
                    <button
                        onClick={scrollToTop}
                        className="scroll-top-btn"
                        aria-label="Remonter en haut"
                    >
                        ↑
                    </button>
                )}
            </div>
            
            <Footer />
            <Header />
        </div>
    );
}

export default Video;